import React from 'react';
import { useSelector } from 'react-redux';

const FinalStep = () => {
    const userInput = useSelector(state => state.form);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add your submit logic here
    };

    return (
        <div className="flex flex-col items-center space-y-5">
            <h2 className="text-2xl font-semibold">Summary</h2>
            <div className="w-full p-4 border rounded-lg">
                <div>
                    <strong>Address:</strong> {userInput.address || 'N/A'}
                </div>
                <div>
                    <strong>Name:</strong> {userInput.name || 'N/A'}
                </div>
                <div>
                    <strong>Email:</strong> {userInput.email || 'N/A'}
                </div>
                <div>
                    <strong>Phone:</strong> {userInput.phone || 'N/A'}
                </div>
                <div>
                    <strong>Current Roof Type:</strong> {userInput.currentRoof || 'N/A'}
                </div>
                <div>
                    <strong>New Roof Type:</strong> {userInput.newRoof || 'N/A'}
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <button className="bg-blue-500 text-white px-3 py-2 rounded" type="submit">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default FinalStep;
