import React, { useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/solid';

const AddressStep = ({ address, handleAddressChange, handleAddressSelect, mapVisible, location: mapLocation }) => {
    const navigate = useNavigate();
    const locationObj = useLocation();
    const queryParams = new URLSearchParams(locationObj.search);
    const addressFromURL = queryParams.get('addr');

    useEffect(() => {
        const storedAddress = localStorage.getItem('address');
        if (addressFromURL && addressFromURL !== address) {
            handleAddressChange(addressFromURL);
            handleAddressSelect(addressFromURL); 
        } else if (storedAddress && !addressFromURL) {
            handleAddressChange(storedAddress);
            handleAddressSelect(storedAddress);
            updateURLWithAddress(storedAddress);
        }
    }, [addressFromURL, handleAddressChange, handleAddressSelect, address]);

    const updateURLWithAddress = (selectedAddress) => {
        queryParams.set('addr', selectedAddress);
        navigate('/address?' + queryParams.toString());
    };

    const handleClearAddress = () => {
        handleAddressChange('');  // Clear the address

        // Remove address from localStorage
        localStorage.removeItem('address');

        // Remove address from URL
        queryParams.delete('addr');
        navigate('/address?' + queryParams.toString());
    };

    const enhancedHandleAddressSelect = (value) => {
        handleAddressSelect(value);
        updateURLWithAddress(value);
        localStorage.setItem('address', value);
    };

    return (
        <div className="flex z-10">
            <div className="prose bg-white rounded-2xl w-full">
                <div className="flex justify-center flex-col">
                    <h1 className="mb-2 p-0">Enter Your Address</h1>
                </div>
                <div className="flex justify-center flex-col">
                    <h6 className="mt-0">We use satellite imagery to measure your roof and provide an instant estimate</h6>
                </div>
                <div className="space-y-1 mt-4">
                    <PlacesAutocomplete
                        value={address}
                        onChange={(value) => handleAddressChange(value)}
                        searchOptions={{ country: 'us' }}
                    >
                       {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="space-y-0">
                                <label className="label">
                                    <span className="label-text">Address</span>
                                </label>
                                <div className="relative mb-10">
    <input
        {...getInputProps({
            type: "text",
            className: "input input-bordered w-full m-0",
            autocomplete: "new-password", // This will discourage browsers and password managers from autofilling
            id: "search-whatever-you-want" // Add your desired ID here
        })}
    />
    {address && 
       <button 
           onClick={handleClearAddress} 
           className="absolute right-2 top-1/2 transform -translate-y-1/2"
       >
           <XCircleIcon className="h-6 w-6 text-gray-500" />
       </button>
    }
    { suggestions.length > 0 && (
        <ul
            id="coincidences_list"
            className="bg-white border-[1px] rounded-lg shadow-lg p-0 m-0 absolute top-full left-0 max-h-[200px] overflow-y-auto w-full text-left"
            style={{ zIndex: 1000 }}
        >
            { suggestions.map((item) => (
                <li
                    key={item.placeId}
                    {...getSuggestionItemProps(item, {
                        onClick: () => enhancedHandleAddressSelect(item.description)
                    })}
                    className="min-h-10 w-full border-b-[1px] border-solid border-l-gray-300 py-2 px-4 cursor-pointer hover:bg-gray-200 m-0"
                >
                    { item.description }
                </li>
            ))}
        </ul>
    )}
</div>

                            </div>
                        )}
                    </PlacesAutocomplete>
                    <div className="h-60 md:h-80 lg:h-96 relative">
                        {mapVisible && mapLocation && (
                            <GoogleMap
                                key={mapLocation ? mapLocation.lat + mapLocation.lng : ''}
                                mapContainerStyle={{ width: '100%', height: '100%' }}
                                center={mapLocation}
                                zoom={19}
                                options={{
                                    streetViewControl: false,
                                    scaleControl: false,
                                    mapTypeControl: false,
                                    panControl: false,
                                    zoomControl: false,
                                    fullscreenControl: false,
                                    mapTypeId: 'satellite',
                                    rotateControl: false
                                }}
                            >
                                <Marker position={mapLocation} />
                            </GoogleMap>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );       
};

export default AddressStep;
