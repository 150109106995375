import React, { useState, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'; 
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import AddressStep from './AddressStep';
import FinalStep from './FinalStep';
import UserInfoStep from './UserInfoStep';
import StatsStep from './StatsStep';


const googleLibraries = ["places"];
const serverUrl = process.env.REACT_APP_SERVER_URL;

const RoofingForm = () => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const navigate = useNavigate();
    const location = useLocation();
    const userInput = useSelector(state => state.form);
    const dispatch = useDispatch();

    const [sessionId, setSessionId] = useState(uuidv4());
    const [mapRef, setMapRef] = useState(null);
    const [mapVisible, setMapVisible] = useState(false);
    const [localAddress, setLocalAddress] = useState("");
    const [locationData, setLocationData] = useState(null);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [propertyData, setPropertyData] = useState(null);
    const [geoCodeResult, setGeoCodeResult] = useState(null);


    const sendDataToServer = async () => {
        const browserData = {
            _id: sessionId,
            appName: "instant-quote",
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            language: navigator.language
        };

        const dataToSend = {
            ...browserData,
            _id: sessionId,
            userInput,
            localStorageData: localStorage.getItem('address')
        };

        try {
            await fetch(`${serverUrl}/api/logSession`, {  
                method: 'POST',  
                headers: {  
                    'Content-Type': 'application/json'  
                },  
                body: JSON.stringify(dataToSend)  
            });
        } catch (error) {}
    };

    const navigateToNextStep = () => {
        const nextSteps = {
            "/": "/address",
            "/address": "/stats",
            "/stats": "/user-info",
            "/user-info": "/final"
        };
    
       if (location.pathname === "/address" && geoCodeResult) {
        let zipCode = geoCodeResult.address_components.find(component => component.types.includes('postal_code')).short_name;
    
        // Split the address by comma, remove the country, and then join without commas
        let addressParts = userInput.address.split(',');
        addressParts.pop(); // Remove country ("USA")
        let strippedAddress = addressParts.join(' ').trim();
    
        // Construct the address without the country and commas
        let modifiedAddress = `${strippedAddress} ${zipCode}`;
    
    
            // Trigger the HTTP request here
            fetch('https://zillow56.p.rapidapi.com/search_address?address=' + encodeURIComponent(modifiedAddress), {
                method: 'GET',
                headers: {
                    'X-Rapidapi-Key': 'db680d4e6dmsh49e64c0bed6b274p18d43ejsnd94df703f72f',
                    'X-Rapidapi-Host': 'zillow56.p.rapidapi.com',
                    'Host': 'zillow56.p.rapidapi.com'
                }
            })
            .then(response => response.json())
            .then(data => setPropertyData(data))
            .catch(error => console.error('Error fetching property data:', error));
        }
        
        const nextPath = nextSteps[location.pathname];
        if (isValidToProceed() && nextPath) {
            navigate(nextPath);
        }
        sendDataToServer().catch(() => {});
    };


    useEffect(() => {
        if (location.pathname === "/") {
            navigate("/address");
        }

        window.addEventListener('storage', sendDataToServer);

        const stepMapping = {
            "/address": 1,
            "/stats": 2,
            "/user-info": 3,
            "/final": 4
        };
        setCurrentStep(stepMapping[location.pathname] || 0);

        return () => {
            window.removeEventListener('storage', sendDataToServer);
        };
    }, [location]);

    const handleChange = (event) => {
        const { id, value } = event.target;
        dispatch({ type: 'UPDATE_FORM', payload: { [id]: value } });
    };

    const handleAddressChange = (value) => {
        setLocalAddress(value);
    };

    const handleAddressSelect = (value) => {
        geocodeByAddress(value)
            .then((results) => {
                setGeoCodeResult(results[0]);  // Store the full result
                return getLatLng(results[0]);
            })
            .then((latLng) => {
                setLocationData(latLng);
                setMapVisible(true); 
            })
            .catch(() => {});
        
        setLocalAddress(value);
        dispatch({ type: 'UPDATE_FORM', payload: { address: value } });
    };
    

    const isUserInfoComplete = () => {
        return userInput.name && userInput.email && userInput.phone;
    };

    const isValidToProceed = () => {
        if (currentStep === 1) {
            return !!userInput.address;
        } else if (currentStep === 2) {
            return true;  // Validation for StatsStep, adjust as needed
        } else if (currentStep === 3) {
            return isUserInfoComplete();
        }
        return true;
    };
    
    const progressPercentage = (currentStep / 4) * 100;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-10">
            <img src="/images/logos/logo-200px.png" alt="Roofing Logo" className="mb-4 w-20" />
            <progress className="progress progress-primary w-full md:w-4/5 lg:w-2/5 mb-4 mt-10" value={progressPercentage} max="100"></progress>
            <div class="w-full md:w-4/5 lg:w-2/5 h-full">
            <form 
                onSubmit={e => e.preventDefault()} 
                className="bg-white shadow-xl flex flex-col p-8 relative rounded-lg"
            >
                <div className="flex-grow overflow-y-auto">
                    <LoadScript
                        googleMapsApiKey={apiKey}
                        preventGoogleMapsLoad={false}
                        onLoad={() => setIsScriptLoaded(true)}
                        libraries={googleLibraries}
                    >
                        <Routes>
                        <Route path="/stats" element={<StatsStep propertyData={propertyData} />} />
                            <Route path="/user-info" element={<UserInfoStep userInput={userInput} handleChange={handleChange} />} />
                            <Route path="/final" element={<FinalStep />} />
                            <Route path="/address" element={<AddressStep address={localAddress} handleAddressChange={handleAddressChange} handleAddressSelect={handleAddressSelect} mapVisible={mapVisible} location={locationData} isScriptLoaded={isScriptLoaded} setMapRef={setMapRef} />} />
                            <Route index path="/" element={<AddressStep address={localAddress} handleAddressChange={handleAddressChange} handleAddressSelect={handleAddressSelect} mapVisible={mapVisible} location={locationData} isScriptLoaded={isScriptLoaded} setMapRef={setMapRef} />} />
                        </Routes>
                    </LoadScript>
                </div>
                <div className="bottom-0 left-0 w-full flex flex-col sm:flex-row sm:justify-end space-y-2 sm:space-y-0 sm:space-x-4 mt-4"> 
                    {currentStep > 1 && 
                        <button 
                            onClick={() => navigate(-1)} 
                            className="btn w-full sm:w-28 py-2"
                        >
                            Previous
                        </button>
                    }
                    {currentStep > 0 && currentStep < 4 && 
                        <button 
                            onClick={navigateToNextStep} 
                            className="btn btn-primary w-full sm:w-28 py-2"
                            disabled={!isValidToProceed()}
                        >
                            Next
                        </button>
                    }
                </div>
            </form>
            </div>
        </div>
    );        
};

export default RoofingForm;
