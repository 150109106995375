import React from 'react';

const StatsStep = ({ propertyData }) => {

  return (
<div className="flex z-10">
    <div className="prose bg-white rounded-2xl w-full">
        <div className="flex justify-center flex-col">
                    <h1 className="mb-2 p-0">Nice House!</h1>
                </div>
                <div className="flex justify-center flex-col">
                    <h6 className="mt-0">We like your house</h6>
                </div>
                <div className="space-y-6 mt-4">
                <div>
            {propertyData && (
                <>
                    <p>Year Built: {propertyData.yearBuilt}</p>
                    <p>Congrats on buying your house in {
                        new Date(propertyData.priceHistory
                            .filter(history => history.event === "Sold")
                            .sort((a, b) => new Date(b.date) - new Date(a.date))[0].date).getFullYear()
                    }</p>
                </>
            )}
        </div>
        </div>
    </div>
</div>
  );
};

export default StatsStep;