import React from 'react';
import RoofingForm from "./components/RoofingForm";
import './App.css';
import './tailwind.output.css';

function App() {
  return (
    <div className="App">
      <RoofingForm />
    </div>
  );
}

export default App;
