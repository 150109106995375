import { v4 as uuidv4 } from 'uuid';

const existingUserId = localStorage.getItem('userId') || uuidv4();
if (!localStorage.getItem('userId')) {
    localStorage.setItem('userId', existingUserId);
}

const initialState = {
    name: "",
    email: "",
    phone: "",
    currentRoof: "",
    newRoof: "",
    startTime: "",
    financing: "",
    address: "",
    userId: existingUserId,
    timeline: ""
};

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_FORM':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default formReducer;
