import React from 'react';

const UserInfoStep = ({ userInput, handleChange }) => {
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handlePhoneChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    handleChange({ target: { id: 'phone', value: formattedPhoneNumber } });
  };

  return (
<div className="flex z-10">
    <div className="prose bg-white rounded-2xl w-full">
        <div className="flex justify-center flex-col">
                    <h1 className="mb-2 p-0">Enter Your Information</h1>
                </div>
                <div className="flex justify-center flex-col">
                    <h6 className="mt-0">Lets Get Started</h6>
                </div>
                <div className="space-y-6 mt-4">
            <div className="form-control">
                <label htmlFor="name" className="text-left">Full Name</label> {/* left-aligned */}
                <input
                    className="input input-bordered w-full"
                    id="name"
                    value={userInput.name}
                    onChange={handleChange}
                />
            </div>
            <div className="form-control">
                <label htmlFor="email" className="text-left">Email</label> {/* left-aligned */}
                <input
                    className="input input-bordered w-full"
                    id="email"
                    value={userInput.email}
                    onChange={handleChange}
                />
            </div>
            <div className="form-control">
                <label htmlFor="phone" className="text-left">Phone Number</label> {/* left-aligned */}
                <input
                    className="input input-bordered w-full"
                    id="phone"
                    value={userInput.phone}
                    onChange={handlePhoneChange}
                />
            </div>
        </div>
    </div>
</div>
  );
};

export default UserInfoStep;